import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, Router } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class RoleGuard {
  constructor(private userService: UserService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const resp = await this.isItOk(route).toPromise();
    if (resp) {
      return true;
    } else {
      this.redirectToLogin(state.url);
      return false;
    }
  }

  async canLoad(route: Route): Promise<boolean> {
    const resp = await this.isItOk(route).toPromise();
    if (resp) {
      return true;
    } else {
      window.location.href = 'https://ggboost.com/404';
      return false;
    }
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const resp = await this.isItOk(childRoute).toPromise();
    if (resp) {
      return true;
    } else {
      window.location.href = 'https://ggboost.com/404';
      return false;
    }
  }

  private isItOk(route: any): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          return this.userService.rememberLogin().pipe(
            switchMap(loginSuccess => {
              if (!loginSuccess) {
                return of(false);
              }
              return this.userService.getUser().pipe(
                map(user => this.checkUserRole(route, user)),
              );
            })
          );
        }
        return of(this.checkUserRole(route, user));
      }),
      catchError(err => {
        window.location.href = 'https://ggboost.com/404';
        return of(false);
      })
    );
  }

  private checkUserRole(route: any, user: any): boolean {
    if (!user) {
      return false;
    }
    const roles = route.data.roles as Array<string>;
    return (roles == null || roles.indexOf(user.role) > -1);
  }

  private redirectToLogin(redirectUrl: string) {
    window.location.href = '/authenticate?redirectTo=' + redirectUrl;
  }
}